<template>
  <!-- :style="{ backgroundImage: 'url(' + require('../assets/img/bg.png') + ')' }" -->
  <div class="login_container">
    <div class="login_box">
      <!-- 头像区域 -->
      <div class="avatar_box">
        <img :src="sysLogo"/>
      </div>
      <!-- 登录表单区域 -->
      <el-form :model="loginForm"
               :rules="loginFormRules"
               ref="loginFormRef"
               label-width="0px"
               class="login_form">
        <!-- 用户名 -->
        <el-form-item prop="userId">
          <!-- 加个小图标 -->
          <el-input placeholder="请输入账号"
                    v-model="loginForm.userId"
                    prefix-icon="iconfont icon-user"></el-input>
        </el-form-item>

        <!-- 密码区域 -->
        <el-form-item prop="password">
          <el-input @keyup.enter.native="login"
                    placeholder="请输入密码"
                    v-model="loginForm.password"
                    type="password"
                    prefix-icon="iconfont icon-3702mima"></el-input>
        </el-form-item>

        <!-- 按钮区域 -->
        <el-form-item class="btns">
          <el-button type="primary"
                     @click="login">登录</el-button>
          <el-button type="info"
                     @click="resetLoginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog title="首次登录请重置密码" :visible.sync="dialogVisible" width="50%">
      <!-- 主要内容 -->
      <el-form label-width="120px">
        <el-form-item label="新密码" >
          <el-input v-model="newpassword" type="password" placeholder="请输入新密码" />
        </el-form-item>
        <el-form-item label="确认新密码" >
          <el-input v-model="cPassword" type="password" placeholder="请再次输入新密码" />
        </el-form-item>
      </el-form>
      <!-- 底部 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="updatePassword">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      type: '',
      sysLogo: '',
      // 这是登录表单的数据对象
      loginForm: {
        userId: '',
        password: '',
      },
      newpassword: '',
      cPassword: '',
      dialogVisible: false,
      // 表单的验证规则对象
      loginFormRules: {
        // 验证用户用户名是否合法
        userId: [
          { required: true, message: '请输入登录名称', trigger: 'blur' },
          {
            min: 3,
            max: 50,
            message: '长度在 3 到 50 个字符',
            trigger: 'blur',
          },
        ],
        // 验证密码是否合法
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          {
            min: 2,
            max: 15,
            message: '长度在 2 到 15 个字符',
            trigger: 'blur',
          },
        ],
      },
      userInfo: {}
    }
  },
  created(){
    const {type, title} = setSysInfo();
    this.type = type;
    // this.type = 'selldata';
    this.sysLogo = `/logo/${type}.png`;
  },
  methods: {
    // 点击重置按钮,重置登录表单
    resetLoginForm () {
      this.$refs.loginFormRef.resetFields()
    },

    // 表单的预验证
    login () {
      this.$refs.loginFormRef.validate(async valid => {
        // console.log(valid);
        if (!valid) return
        // 解构出来 只要里面的data 然后重命名为res对象
        let apiUrl = '/api/AdolfUserModule/adolf-sys-user/SysUserLogin';
        if(this.type === 'selldata'){
          //17679379272
          //2609831394
          apiUrl = '/api/admin/alipay-user-admin/login'
        }
        
        this.$api.postAjaxJson(apiUrl, this.loginForm).then(res => {

          if (res.code !== 200)  return this.$message.error('登录失败')
          if(this.type === 'selldata'){
            window.localStorage.setItem('token', res.data.token)
            window.localStorage.setItem('data-menus', JSON.stringify(res.data.menus))
            return this.$router.push('/dataall')
          }
          window.localStorage.setItem('token', res.data.token)
            

          this.$api.queryMenus({}).then((res) => {
            if (res.code !== 200) return 
            if(res.data){
              window.localStorage.setItem('menus', JSON.stringify(res.data))
            }
          })
          this.userInfo = res.data.userMessage;
          if(res.data.menus){
            window.localStorage.setItem('userMenus', JSON.stringify(res.data.menus))
          }
          //子账户首次登录修改密码  
          if(!res.data.userMessage.nearLoginTime){
            this.dialogVisible = true;
            return;
          } 
          this.loginSuccess();
        })
      })
    },
    updatePassword(){
      const {newpassword, cPassword} = this;
      if(!newpassword) return this.$message.error('请输入新密码')
      if(newpassword.length < 6) return this.$message.error('新密码长度不能少于6位')
      if(!cPassword) return this.$message.error('请再次输入新密码')
      if(cPassword != newpassword) return this.$message.error('两次密码输入不一致')
      
      this.$api.UpdateSysUser({id: this.userInfo.id,password: newpassword}).then((res) => {
        if (res.code !== 200) return this.$message.error('请求失败:' + res.message)
        this.loginSuccess();
      })
    },
    loginSuccess(){
      this.$message.success('登录成功')
          
      // 1.将登陆成功之后的 token 保存到客户端的 sessionStorage中
      // 1.1 项目中出了登陆之外的其他API接口,必须在登陆之后才能访问
      // 1.2 token 只应在当前网站打开期间生效,所以讲token保存在 sessionStorage中
      
      if(this.userInfo){
        window.localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
      }
      
      // 2.通过编程式导航跳转到后台主页,路由地址是 /home
      if(this.userInfo && this.userInfo.role != 1){
        return this.$router.push('/order')
      }
      this.$router.push('/home')
    }
  },
}
</script>

<style lang="scss" scoped>
.login_container {
  background-color: #2b4b6b;
  // background-image: url();
  height: 100%;
}

.login_box {
  width: 450px;
  height: 300px;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  //(x,y)横轴 纵轴
  transform: translate(-50%, -50%);
}

.avatar_box {
  height: 130px;
  width: 130px;
  border: 1px solid #eeeeee;
  // 让边框为圆角
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px #ddd;
  // 位移起来
  position: absolute;
  left: 50%;
  // 这里根据前面盒子的h w=130的一半 也就是65 是向后 向上移动
  transform: translate(-50%, -50%);
  background-color: #fff;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #eee;
  }
}

.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.btns {
  // 变成弹性(要先有这个声明 才能进行下面的对齐方式)
  display: flex;
  //  尾部对齐
  justify-content: flex-end;
}
</style>
